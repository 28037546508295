<template>

  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <!-- Header Section -->
        <Front-Header> </Front-Header>

        <!-- Main Start-->
        <section class="success__section">
          <div class="container">
            <div class="row">
              <!--<div class="col-md-12 head">
                <h2>Payment conformation</h2>
              </div>-->
              <div class="col-md-12 body">
                <div class="payment">
                  <img src="../../assets/front/image/payment-success.jpg" alt="imgage" />
                  <h6 class="payment-success-heading">Payment Successful</h6>
                </div>
              </div>
              <div class="col-md-12 footer">
                <h6>
                  Thank you for your payment
                </h6>
                <router-link to="/" class="btn btn-primary">Back to home</router-link>
              </div>
            </div>
          </div>
        </section>
        <!-- Main End-->

        <!-- Footer Section -->
        <Front-Footer> </Front-Footer>
      </div>
    </div>
  </body>
</template>

<script>
import Header from "./Front-Header";
import Footer from "./Front-Footer";
import Loader from './Loader';
import { db } from "@/main";

export default {
  components: {
    "Front-Header": Header,
    "Front-Footer": Footer,
    'Loader': Loader,
  },
  data() {
    return {
      pages: {},
      total_amount: 0,
    };
  },

  created: function () {
    this.fetchPages();
    this.saveOrder();
  },

  methods: {
    fetchPages() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      db.collection("pages")
        .doc("1RlzYqGvTJdi10SUNiDv")
        .get()
        .then((doc) => {
          console.log("hhhh88");
          console.log(doc.id, " => ", doc.data());
          this.pages = doc.data();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },

    saveOrder() {
      var transaction_id = this.$route.params.id;
      var product_id = this.$route.params.product_id;
      // alert(product_id)
      var sessioin_transaction_id = localStorage.getItem("transaction_id");
      console.log(transaction_id == sessioin_transaction_id);
      if (transaction_id == sessioin_transaction_id) {

        localStorage.removeItem("transaction_id");

        var uid = localStorage.getItem("userSessionId");
        var date = new Date().toLocaleString();

        var affiliate_id = localStorage.getItem("affiliate_id");

        db.collection("orders")
          .add({

            uid: uid,
            created: date,
            modified: date,
          })
          .then((doc) => {



            db.collection("cart")
              .where("uid", "==", uid)
              .where("subscription", "==", product_id)
              .get()
              .then((querySnapshot) => {

                querySnapshot.forEach(async (res) => {

                  this.queryorder = db.collection("order_details").where("uid", "==", uid).where("subscription", "==", res.data().subscription).where("delete_id", "==", 1);
                  const snapshoto = await this.queryorder.get();
                  this.count = snapshoto.size;
                  // alert(this.count);
                  // alert(res.data().subscription);
                  if (this.count == 1) {
                    db.collection("order_details")
                      .where("uid", "==", uid)
                      .where("subscription", "==", res.data().subscription)
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          console.log(doc.id, " => ", doc.data());
                          if (doc.data().subscription == res.data().subscription && doc.data().delete_id == 1) {
                            // alert('fail');
                            db.collection("order_details")
                              .doc(doc.id)
                              .update({
                                delete_id: 0,
                                created: date,
                                modified: date,
                              })
                              .then((doc) => {
                                //alert('done');
                                console.log(doc);
                                db.collection("cart").doc(res.id).delete();
                              })
                              .catch((error) => {
                                console.error("Error writing document: ", error);
                              });
                          }
                        })
                      })
                  } else {
                    db.collection("order_details")
                      .add({
                        subscription: res.data().subscription,
                        uid: res.data().uid,
                        product_id: product_id,
                        order_id: doc.id,
                        created: date,
                        modified: date,
                      })
                      .then((docs) => {
                        //alert('done');
                        console.log(docs);
                        db.collection("cart").doc(res.id).delete();
                      })
                      .catch((error) => {
                        console.error("Error writing document: ", error);
                      });
                  }

                  //this.total_amount = parseFloat(this.total_amount) + parseFloat(res.data().amount);
                  this.total_amount = this.total_amount + res.data().amount;
                  this.order_id = doc.id

                  if (affiliate_id != null) {
                    db.collection("users")
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach((docs) => {
                          if (docs.id == affiliate_id && affiliate_id != uid) {
                            console.log(docs.id)
                            db.collection("affiliates")
                              .add({
                                affiliate_id: affiliate_id,
                                user_id: uid,
                                order_id: this.order_id,
                                product_id: product_id,
                                amount: "50",
                                created: date,
                                modified: date,
                              })
                          }
                        })
                      })
                  }

                  db.collection("orders").doc(doc.id).update({ total_amount: this.total_amount });
                });
                // alert(this.total_amount);
                // db.collection("orders").doc(doc.id).update({ total_amount: this.total_amount });

              });

          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });

      } else {
        this.$router.push("/");
      }

    },
  },
};
</script>
